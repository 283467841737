@import '../../../../scss/theme-bootstrap';

.gnav-link-tout {
  &__header {
    @include gnav-section-title;
    position: relative;
    display: none;
    padding: 0 0 2px;
    color: $color-white;
    @include breakpoint($landscape-only) {
      display: inline-block;
    }
    .active-links-group & {
      box-shadow: 0 1px 0 0 $color-white;
    }
    &:first-child {
      padding-top: 0;
    }
    &-link {
      @include gnav-section-title;
      color: $color-white;
      text-decoration: none;
    }
  }
  &__header-label {
    display: block;
    width: 100%;
    line-height: $gnav-mobile-link-height;
    padding-#{$ldirection}: $gnav-mobile-hpad--outer;
    color: $color-white;
    @include breakpoint($landscape-only) {
      display: inline-block;
      padding-#{$ldirection}: 0;
      line-height: inherit;
      width: auto;
    }
  }
  &__mobile-section-toggle {
    opacity: 1;
    position: absolute;
    top: 0;
    #{$rdirection}: 20px;
    @include breakpoint($landscape-only) {
      display: none;
    }
  }
  &__content {
    #{$ldirection}: 58%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    overflow-y: hidden;
    position: fixed;
    height: 100%;
    top: 0;
    width: 42%;
    visibility: hidden;
    .gnav-links__header ~ & {
      padding: 0;
      @include breakpoint($landscape-only) {
        background-color: transparent;
        height: calc(100% - #{$gnav-mobile-link-height});
      }
    }
    .gnav-link-tout__trigger:checked + .gnav-link-tout.gnav-link-tout__with-tout-panel & {
      @include breakpoint($landscape-only) {
        visibility: visible;
      }
    }
  }
  .gnav-tout {
    display: none;
    height: 100%;
    @include breakpoint($landscape-only) {
      display: block;
    }
  }
  &__section-label {
    .gnav-link-tout__trigger:checked + .gnav-link-tout__with-tout-panel & {
      @include breakpoint($landscape-only) {
        color: $color-white;
      }
    }
  }
  &__bottom_padding {
    padding-bottom: $gnav-panel-content-pad-bottom;
  }
  &__link {
    &--viewall {
      padding: 0;
    }
  }
  &__mobile-icon {
    display: inline-block;
    height: $gnav-icon--inline;
    width: $gnav-icon--inline;
    &.icon--minus {
      display: none;
    }
  }
  &__section-label,
  .gnav-link {
    @include gnav-text-link();
    line-height: get-line-height(15px, 19px);
    padding: 5px $gnav-mobile-hpad--outer;
    @include breakpoint($landscape-only) {
      padding: 5px 0;
    }
  }
}
